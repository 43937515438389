@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

@font-face {
  font-family: "Overpass";
  src: url("assets/fonts/Overpass-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Overpass" ,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 

  font-size: 16px;
}

code {
  font-family: "Overpass";
}

@media screen and (min-width: 1366px){
  body {
    font-size: 18px;
  }
}

@media screen and (min-width: 1920px){
  body {
    font-size: 24px;
  }
}
